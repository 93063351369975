<template>

	<div>
<!--		<van-nav-bar class="navbar" title="工资" fixed left-arrow @click-left="leftReturn" />-->
		<rxNavBar title="工资" androidOrIOSFlag="true"></rxNavBar>
		<!-- 顶部固定区域-->
		<div class="top"> *工资属于敏感信息，注意保密 </div>
		<!-- 主体的列表们 -->
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<div class="lists">
				<div class="detail" v-for=" (item,i) in paylist " :key="i">
					<!-- 上半部分 -->
					<div class="tophalf" >
						<div class="month">{{item.salaryTime}}</div>
<!--						<div class="bottomNavigationB" @click="confrim(item.id)" v-if="item.salaryStatus == '1'">确认</div>-->
					</div>
					<!-- 下半部分 -->
					<div class="bottomhalf">
						<div class="allmoney">总金额：</div>
						<div class="money">{{item.actualAmount}}元</div>
						<div class="mingxi" @click="wageDetail(item.id)">明细></div>
					</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		queryBaseData,
		wageList,
		confirmWage
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil,
		getStaffId
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				show: false,
				showPicker: false,
				isOccupationShow: false,
				loading: false,
				finished: false,
				currentPage: '1', //当前页
				pageCount: '', //总页数
				numberPage: '10', //每页条数
				count: 0,
				certificateType: '',
				isOccupationShow: false,
				defaultIndex: '',
				swi: true,
				isLoading: false,
				totalPage: '',
				paylist: [],
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		created() {
			// this.initData();
			// this.initDicData();
		},
		//初始化
		mounted() {
			// this.certificateType = this.columns[0]
		},
		methods: {
			//工资列表初始化
			wageList() {
				const that = this
				if (that.dictionaryValue == 0) {
					that.dictionaryValue = ""
				}
				let initData = {}
				// debugger
				initData.staff_id = getStaffId() //userid
				initData.currentPage = that.currentPage //当前页
				initData.numberPage = that.numberPage //每页条数
				wageList(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response)
						if (response.data.code == 0) {
							that.pageCount = response.data.data.pageCount //总页数
							var totalPage = response.data.data.totalPage //总条数
							var list = response.data.data.data
							// console.log(list)
							if (totalPage == 0 || that.pageCount == 0 || !list) { //没有数据时
								that.loading = false
								that.finished = true
							}
							if (list && that.pageCount != 0) { //有数据时
								var currentPage = parseInt(that.currentPage); //当前页
								var pages = that.pageCount; //总页数
								if (pages >= currentPage) { //当前页不大于总页数
									that.currentPage = currentPage + 1
									for (let i = 0; i < list.length; i++) {
										that.paylist.push(list[i])
									}
									that.loading = false
									// console.log("长度=" + that.userLeaseChangeListZ.length)
								} else {
									that.loading = false
									that.finished = true
								}
							}
						} else if (response.data.code != 0) {
							responseUtil.alertMsg(that, response.data.msg)
						} else {
							responseUtil.alertMsg(that, response.data.msg)
						}
						// if (1=1) {
						// 	that.swi = false
						// } else {
						// 	that.swi = true
						// }


					})
				})
			},
			wageDetail(id) {
					this.$router.push({
						name: 'wageDetail',
						query: {
							salaryHistory_id:id
						}
					})
			},
			confrim(e) {
				let that = this
				let initData = {}
				initData.staff_id = getStaffId() //userid
				initData.salaryHistoryDetail_id = e
				confirmWage(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
                        that.wageList()
					})
				})
			},
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			default () { //默认值
			},
			//分页
			onLoad() {
				this.wageList();
			},
		},

	}
</script>

<style scoped>
	.navbar {
		background-color: white;
		border-bottom: 2px solid white;
	}

	.top {
		color: #FF5D3B;
		position: fixed;
		width: 100%;
		height: 45px;
		line-height: 45px;
		top: 50px;
		font-size: 16px;
		text-align: center;
		background-color: rgb(248, 248, 248);
	}

	.lists {
		margin-top: 50px;
	}

	.detail {
		width: 90%;
		height: 110px;
		border-radius: 8px;
		background-color: white;
		margin: 0 auto;
		margin-bottom: 15px;
	}

	.tophalf {
		height: 60px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
	}

	.month {
		float: left;
		font-size: 18px !important;
		font-weight: 700;
		font-size: 22px;
		padding-top: 22px;
		padding-left: 15px;
	}

	.bottomNavigationB {
		height: 30px;
		width: 90px;
		font-size: 12px;
		align-items: center;
		border-radius: 7px;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: white;
		line-height: 30px;
		box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
		font-family: PingFangSC-Semibold;
		text-align: center;
		float: right;
		margin-top: 15px;
		margin-right: 10px;
	}

	.allmoney {
		float: left;
		font-size: 16px;
		margin-top: 10px;
		margin-left: 15px;
		font-weight: 600;
	}

	.money {
		float: left;
		margin-top: 12px;
		color: #ACACAC;
		font-size: 13px;
	}

	.mingxi {
		float: left;
		margin-top: 12px;
		color: rgba(255, 93, 59, 1);
		font-size: 13px;
		margin-left: 10px;
		text-decoration: underline
	}
</style>
